<template>
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
        
        <a-form 
            :form="form" 
            style="padding:0 24px" 
            :label-col="{span:5}" 
            :wrapper-col="{span:12}"
            @submit="handleSubmit"
            >
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <h5>{{$t('goal.edit.description')}}</h5>
            </a-form-item>
            <a-form-item label="Name">
                <a-input
                v-decorator="['name', { rules: [{ required: true, message: 'Please input name!' }] }]"
                ></a-input>
            </a-form-item> 
            <a-form-item label="Description">
                <a-textarea
                v-decorator="['description', { rules: [{ required: false, message: 'Please input description!' }] }]"
                >
                </a-textarea>
            </a-form-item>  
        
            <!-- <a-form-item label="KPI">
                <a-input
                v-decorator="['kpi', { rules: [{ required: true, message: 'Please input kpi!' }] }]"
                ></a-input>
            </a-form-item>  -->
            <a-form-item :label="$t('goal.edit.label.kpi')">
                <a-select v-decorator="['kpi',{rules:[{required:true}]}]" @change="changekpi">
                    <a-select-option value="price">product price</a-select-option>
                    <a-select-option value="sales">product sales</a-select-option>
                </a-select>
            </a-form-item>
            <!-- <a-form-item label="Relationship">
                <a-input
                v-decorator="['relationship', { rules: [{ required: true, message: 'Please input relationship!' }] }]"
                ></a-input>
            </a-form-item>  -->
            <a-form-item :label="$t('goal.edit.label.relationship')">
                <a-select v-decorator="['relationship',{rules:[{required:true}]}]" @change="changere">
                    <a-select-option value="lower">lower</a-select-option>
                    <a-select-option value="higher">higher</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="Threshold (%)">
                <a-input
                v-decorator="['threshold', { rules: [{ required: true, message: 'Please input threshold!' }] }]"
                ></a-input>
            </a-form-item>
            <a-form-item label="Categories" v-if="!matchallvalue" v-show="showcategory">
                <a-checkbox-group style="display:inline" v-decorator="['categories']">
                    <template v-for="item in allcategories" >
                    <a-checkbox :value="item.id" :key="item.id">{{item.name}}</a-checkbox>
                    </template>
                </a-checkbox-group>
            </a-form-item>
            <a-form-item label="Match all products" v-show="showmatchall">
                <a-checkbox v-decorator="['matchall']" v-model="matchallvalue">Match all products</a-checkbox>
            </a-form-item> 
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit" icon="save" @click="info">Save</a-button>
                <a-space></a-space>
                <a-button type="secondary" icon="close" style="margin-left:10px" @click="backtocategorylist">Cancel</a-button>
            </a-form-item>
        </a-form>
    </a-card>
</template>
<script>
import {addorupdate, getitem} from '@/api/goal';
import {list} from '@/api/category';
import { message } from 'ant-design-vue';

export default ({
    methods: {
        info(){
            message.success('This goal has been saved successfully!');
        },
        // function: goal-07
        backtocategorylist(){
            this.$router.go(-1);
        },
        handleChange(value) {
            console.log(`selected ${value}`);
        },
        changekpi(v,o){
            this.kpi = v;
        },
        changere(v,o){
            this.relationship = v;
        },
        // function: goal-06
        handleSubmit(e){
            e.preventDefault();
            this.form.validateFields((err,values)=>{
                if (!err){
                    values.id=this.goalid;
                    
                    if(!values.categories && !values.matchall){
                        var frameArray= [];
                        frameArray.push(this.categoryid)
                        values.categories = frameArray
                    }
                    console.log(values)
                    addorupdate(values).then(data=>{
                        this.$router.go(-1);
                    });
                }
            });
        }
    },
    data() {
        return {
            form: this.$form.createForm(this,{name: 'goal'}),
            allcategories: [],
            matchallvalue:false,
            showmatchall:true,
            showcategory:true,
        };
    },
    mounted() {
        var id = this.goalid;
        list().then(result=>{
            this.allcategories= [];
            for (var i = 0;i<result.length;i++)
            {
                if (this.goaltype=="2")
                    this.allcategories.push({name:result[i].name,id:result[i].categoryid,type:result[i].categorytype});
                else {
                    if (result[i].categorytype!="2")
                        this.allcategories.push({name:result[i].name,id:result[i].categoryid,type:result[i].categorytype});
                }
            }
            if (id==0){
                this.form.setFieldsValue(
                    {
                        name:'',
                        description:"",
                        kpi:"",
                        relationship:"",
                        theshold:""
                    }
                );
            } else {
                getitem(id).then(data=>{
                    this.form.setFieldsValue(
                        {
                            name: data.name,
                            description: data.description,
                            kpi: data.kpi,
                            relationship: data.relationship,
                            threshold: data.threshold,
                            matchall: data.matchall,
                            categories: data.categories
                        }
                    );
                    this.matchallvalue = data.matchall;
                });
            }
            if (this.goaltype=="2"){
                this.matchallvalue = false;
                this.showmatchall = false;
                for (var i=0; i<this.allcategories.length;i++)
                {
                    if (this.allcategories[i].id == this.categoryid)
                    {
                        this.allcategories = [this.allcategories[i]];
                        break;
                    }
                }
                this.form.setFieldsValue({categories:[this.allcategories[0].id]});
                this.showcategory=false;
            }
        });

    },
    props: {
        goalid: String,
        categoryid: String,
        goaltype: [Number,String]
    }
})
</script>
